import React from 'react';

const Footer = (props) => {
    let now = new Date();
    let yearCurrent = now.getFullYear();

    return (
        <React.Fragment>
        <hr style={{ marginTop: '3rem', marginBottom: '0' }} />
        <div style={{ fontSize: '0.8rem', width: '98%', margin: '0 auto' }}>
            <span>&copy;1970-{ yearCurrent } 光淙股份有限公司</span>
            <span style={{ float: 'right' }}>Powered by HARDCORP</span>
        </div>
        </React.Fragment>
    );
};

export default Footer;