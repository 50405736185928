import React, { useEffect, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import './App.css';

import * as actions from './store/actions/index';
import Layout from './containers/Layout/Layout';
import SpinnerFixed from './components/UI/Spinner/SpinnerFixed';
import Spinner from './components/UI/Spinner/Spinner';
const Login = React.lazy(() => import('./containers/User/Login/Login'));
const Logout = React.lazy(() => import('./containers/User/Logout/Logout'));
const Home = React.lazy(() => import('./containers/Home/Home'));
const UserPwChange = React.lazy(() => import('./components/User/UserPwChange'));
const Category = React.lazy(() => import('./components/Category/Category'));
const CategoryList = React.lazy(() => import('./containers/Category/CategoryList'));
const CategorySingle = React.lazy(() => import('./containers/Category/CategorySingle'));
const ProductList = React.lazy(() => import ('./containers/Product/ProductList'));
const ProductSingle = React.lazy(() => import ('./containers/Product/ProductSingle'));
const ProductVar = React.lazy(() => import ('./containers/Product/ProductVar'));
const ProductDiscount = React.lazy(() => import ('./containers/Product/ProductDiscount'));
const ProductInvUpload = React.lazy(() => import ('./containers/Product/ProductInvUpload'));
const DealList = React.lazy(() => import ('./containers/Deal/DealList'));
const DealSingle = React.lazy(() => import ('./containers/Deal/DealSingle'));
const UserDetail = React.lazy(() => import ('./containers/User/UserDetail'));
const UserList = React.lazy(() => import('./containers/User/UserList'));
const UserSingle = React.lazy(() => import('./containers/User/UserSingle'));
const Api = React.lazy(() => import('./components/Api'));
const UserPwSet = React.lazy(() => import('./containers/User/UserPwSet'));
const UserPwReset = React.lazy(() => import('./containers/User/UserPwReset'));
const SettingEcom = React.lazy(() => import('./containers/Admin/SettingEcom'));
const OrderTotalOnly = React.lazy(() => import('./containers/Order/OrderTotalOnly'));
const OrderCustom = React.lazy(() => import('./containers/Order/OrderCustom'));
const OrderList = React.lazy(() => import('./containers/Order/OrderList'));
const OrderSingle = React.lazy(() => import('./containers/Order/OrderSingle'));
const OrderPay = React.lazy(() => import('./containers/Order/OrderPay'));
const OrderShip = React.lazy(() => import('./containers/Order/OrderShip'));
const Chat = React.lazy(() => import('./containers/Chat/Chat'));
const ClientList = React.lazy(() => import('./containers/Client/ClientListB'));
const ClientSingle = React.lazy(() => import('./containers/Client/ClientSingle'));
const BannerDesktopMgmt = React.lazy(() => import('./containers/Homepage/BannerDesktopMgmt'));
const BannerMobileMgmt = React.lazy(() => import('./containers/Homepage/BannerMobileMgmt'));
const FollowUsMgmt = React.lazy(() => import('./containers/Homepage/FollowUsMgmt'));
const NewsList = React.lazy(() => import('./containers/News/NewsList'));
const NewsSingle = React.lazy(() => import('./containers/News/NewsSingle'));
const PayEcomList = React.lazy(() => import('./containers/Payable/PayEcomList'));
const RecvEcomList = React.lazy(() => import('./containers/Receivable/RecvEcomList'));
const EcomAboutUs = React.lazy(() => import('./containers/Admin/EcomAboutUs'));
const EcomPrivacyPolicy = React.lazy(() => import('./containers/Admin/EcomPrivacyPolicy'));
const EcomReturnPolicy = React.lazy(() => import('./containers/Admin/EcomReturnPolicy'));
const EcomVIPPolicy = React.lazy(() => import('./containers/Admin/EcomVIPPolicy'));
const EcomRegisterPolicy = React.lazy(() => import('./containers/Admin/EcomRegisterPolicy'));
const DataDownload = React.lazy(() => import('./containers/Data/DataDownload'));
const EdmMailingList = React.lazy(() => import('./containers/Edm/EdmMailingList'));
const EdmList = React.lazy(() => import('./containers/Edm/EdmList'));
const EdmSingle = React.lazy(() => import('./containers/Edm/EdmSingle'));
const FaqList = React.lazy(() => import('./containers/Faq/FaqList'));
const FaqSingle = React.lazy(() => import('./containers/Faq/FaqSingle'));
const DiscountPublicList = React.lazy(() => import('./containers/Discount/DiscountPublicList'));
const DiscountPublicSingle = React.lazy(() => import('./containers/Discount/DiscountPublicSingle'));
const DiscountRebateList = React.lazy(() => import('./containers/Discount/DiscountRebateList'));
const DiscountRebateSingle = React.lazy(() => import('./containers/Discount/DiscountRebateSingle'));
const SinopacCCCheck = React.lazy(() => import('./containers/Finance/SinopacCCCheck'));

const App = (props) => {
    // console.log(window.location.pathname);
    // console.log(props.isAuthenticated);
    // console.log(props.history);
    useEffect(() => {
        props.onTryAutoSignin();
        // eslint-disable-next-line
    }, []);

    let routes = (
        <Switch>
            <Route path='/sinopac_cc_check' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><SinopacCCCheck 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_register_policy' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EcomRegisterPolicy 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_privacy_policy' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EcomPrivacyPolicy 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_return_policy' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EcomReturnPolicy 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_vip_policy' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EcomVIPPolicy 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_about_us' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EcomAboutUs 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/edm_mailing_lists' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EdmMailingList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/edms' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EdmList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/edm/:idEdm' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><EdmSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/faqs' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><FaqList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/faq/:idFaq' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><FaqSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/changeUserPw' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><UserPwChange 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/data_download' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><DataDownload 
                isAuthenticated={props.isAuthenticated}
                permDataDownload={props.permDataDownload}
            /></Suspense>) } />
            <Route path='/settingEcom' render={ () => (<Suspense fallback={<div><Spinner /></div>}><SettingEcom 
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
            /></Suspense>) } />
            <Route path='/resetUserPw' render={ () => (<Suspense fallback={<div><Spinner /></div>}><UserPwReset /></Suspense>) } />
            <Route path='/inv_upload' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ProductInvUpload 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/userDetail' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><UserDetail 
                isAuthenticated={ props.isAuthenticated }
                idUser={ props.idUser }
                mode="user_detail"
            /></Suspense>) } />
            <Route path='/variation/:idProduct' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ProductVar 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/setUserPw' render={ () => (<Suspense fallback={<div><Spinner /></div>}><UserPwSet /></Suspense>) } />
            <Route path='/categorys' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><CategoryList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/discount_public/:idDiscount' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><DiscountPublicSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/discount_public' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><DiscountPublicList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/discount_rebate/:idDiscount' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><DiscountRebateSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/discount_rebate' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><DiscountRebateList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/discount/:idProduct' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ProductDiscount 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/followUs' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><FollowUsMgmt
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/category/:idCategory' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><CategorySingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/categorie' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><Category 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/products' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ProductList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/product/:idProduct' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ProductSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_ap' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><PayEcomList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ecom_ar' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><RecvEcomList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/clients' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ClientList
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/banners_desktop' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><BannerDesktopMgmt
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/banners_mobile' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><BannerMobileMgmt
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/client/:idClient' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><ClientSingle
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
                isSales={ props.isSales }
            /></Suspense>) } />
            <Route path='/orders' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/order/:idOrder' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderSingle 
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
                idUser={ props.idUser }
            /></Suspense>) } />
            <Route path='/order_custom' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderCustom 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/order_total_only' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderTotalOnly 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/logout' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><Logout /></Suspense>) } />
            <Route path="/login" render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><Login /></Suspense>) } />
            <Route path='/users' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><UserList
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
            /></Suspense>) } />
            <Route path='/deals' render={ () => (<Suspense fallback={<div><Spinner /></div>}><DealList 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/user/:idUser' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><UserSingle
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
            /></Suspense>) } />
            <Route path='/chat' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><Chat 
                idUser={ props.idUser }
                isAuthenticated={ props.isAuthenticated }
                nameFirst={ props.nameFirst }
            /></Suspense>) } />
            <Route path='/news/:idNews' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><NewsSingle
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/news' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><NewsList
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/deal/:idDeal' render={ () => (<Suspense fallback={<div><Spinner /></div>}><DealSingle 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/ship/:idOrder' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderShip 
                isAuthenticated={ props.isAuthenticated }
            /></Suspense>) } />
            <Route path='/pay/:idOrder' render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><OrderPay 
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
            /></Suspense>) } />
            <Route path='/api' render={ () => (<Suspense fallback={<div><Spinner /></div>}><Api /></Suspense>) } />
            <Route path="/" exact render={ () => (<Suspense fallback={<div><SpinnerFixed /></div>}><Home 
                isAuthenticated={ props.isAuthenticated }
                isAdmin={ props.isAdmin }
                isSales={ props.isSales }
                isFinance={ props.isFinance }
            /></Suspense>) } />
            <Redirect to="/login" />
        </Switch>
    );

    return (
        <Layout>
            { routes }
        </Layout>
    );
}

const mapStateToProps = state => {
    return {
        permDataDownload: state.auth.permDataDownload,
        isAuthenticated: state.auth.token !== null,
        idUser: state.auth.idUser,
        isSales: state.auth.isSales,
        isFinance: state.auth.isFinance,
        isAdmin: state.auth.isAdmin,
        nameFirst: state.auth.nameFirst,
        nameLast: state.auth.nameLast,
        authRedirectPath: state.auth.authRedirectPath,
    };    
}

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignin: () => dispatch(actions.checkAuthState()),
    };
};
  
export default connect(mapStateToProps, mapDispatchToProps)(App);
